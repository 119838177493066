<script setup lang="ts">
  const props = defineProps<{
    context: string
    query: string
    options?: {
      highlightClass?: string
      minQueryLength?: number
    }
  }>()

  const contextPartsByQuery = computed<string[]>(() => {
    return props.context.split(props.query)
  })
</script>

<template>
  <span>
    <template v-if="!query.length || !context.length">{{ context }}</template>

    <template v-else>
      <span
        v-for="(part, index) in contextPartsByQuery"
        :key="index"
      >
        <span>{{ part }}</span>

        <span
          v-if="index < contextPartsByQuery.length - 1"
          :class="[options?.highlightClass || 'text-highlight']"
          >{{ query }}</span
        >
      </span>
    </template>
  </span>
</template>
